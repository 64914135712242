import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select, notification, InputNumber, Modal, Upload, Spin } from 'antd';
import { useParams } from "react-router-dom";
import _ from 'underscore';
import { NewColorProduct, UpdateConfigEditor, VideoBox, ImageBox } from "../../components";
import usePrompt from "../../hooks/usePrompt";
import {
  CloseOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";

import "./EditProduct.css"
import TextArea from "antd/lib/input/TextArea";
import { getChangedValues, getBase64, loncaRound, uploadChartImage, getTranslatedProductName, isMediaListExceedSizeThreshold, uploadMedias, loncaFormatDate } from "../../helpers"
import { fetchProductByHandle, fetchUpdateProduct, fetchCreateProduct, fetchAllProductsHandle } from "../../redux/productSlice";
import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import { fetchVendor } from "../../redux/vendorSlice";
import { fetchGroupById, fetchInsertGroup, fetchUpdateGroupById } from "../../redux/productGroup";
import { DESI_CONSTANT } from "../../constants";
import TagSelector from "../../components/TagSelector/TagSelector";

const EditProduct = () => {
  const { t } = useTranslation('translation');
  const { handle } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, _] = useSearchParams();

  const { Option } = Select;
  const [form] = Form.useForm();

  const addNew = handle === "new";

  const { user } = useSelector((state) => state.user);
  const { tags } = useSelector((state) => state.tags);
  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { allProductTypes: productTypesMain } = useSelector((state) => state.productTypes);
  const { colors } = useSelector((state) => state.colors);
  const { genders } = useSelector((state) => state.gender);
  const { vendor } = useSelector((state) => state.vendor);
  const { selectedLanguage } = useSelector((state) => state.user);
  const selectedLanguageCode = selectedLanguage === "tr" ? "tr" : "en";
  const { allProductsHandles } = useSelector((state) => state.products)
  const [TL_USD_BUYING, setTL_USD_BUYING] = useState();
  const [TL_USD_SELLING, setTL_USD_SELLING] = useState();
  const [product, setProduct] = useState(null);
  const [skuCode, setSkuCode] = useState("");
  const [series, setSeries] = useState(seriesMain);
  const [productTypes, setProductTypes] = useState(productTypesMain);
  const [pricetl, setPricetl] = useState(0);
  const [priceusd, setPriceusd] = useState(0);
  const [cogstl, setCogstl] = useState(0);
  const [cogsusd, setCogsusd] = useState(0);
  const [measurement, setMeasurement] = useState();
  const [isDirty, setIsDirty] = useState(false);
  const [discountCheckbox, setDiscountCheckbox] = useState(false);
  const [discountTL, setDiscountTL] = useState(0);
  const [discountUSD, setDiscountUSD] = useState(0);
  const [discountCogsTL, setDiscountCogsTL] = useState(0);
  const [discountCogsUSD, setDiscountCogsUSD] = useState(0);
  const [tab, setTab] = useState("home");
  const [fileList, setFileList] = useState([]);
  const [videoFileList, setVideoFileList] = useState([]);
  const [chartFileList, setChartFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewType, setPreviewType] = useState('image');
  const [selectedImage, setSelectedImage] = useState();
  const [isFetchingAllProducts, setIsFetchingAllProducts] = useState(false);

  const [productGroup, setProductGroup] = useState({ products: [] });
  const [productGroupDirty, setProductGroupDirty] = useState(false);
  const [productGroupRemoved, setProductGroupRemoved] = useState([]);
  const [productGroupInput, setProductGroupInput] = useState('');
  const [loading, setLoading] = useState(false);

  const [compoundMargin, setCompoundMargin] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewColorProductCreated, setIsNewColorProductCreated] = useState(false);

  const [priceInitial, setPriceInitial] = useState(0);
  const [statusInitial, setStatusInitial] = useState("");
  const [seriesInitial, setSeriesInitial] = useState("");
  const [productTypeIdInitial, setProductTypeIdInitial] = useState("");
  const [refresh, setRefresh] = useState(false);

  const updateTabParam = (newTab) => {
    const newUrl = `${location.pathname}?tab=${newTab}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setTab(newTab);
  };

  useEffect(() => {
    setTab(searchParams?.get("tab") || "home");
  }, [searchParams?.get("tab")]);

  useEffect(() => {
    setCompoundMargin(((1 + (vendor?.lonca_vendor_margin / 100)) * (1 + (vendor?.lonca_boutique_margin / 100))));
  }, [vendor]);

  usePrompt(t(`errors.You have unsaved changes, do you want to leave?`), isDirty);

  useEffect(() => {
    if (!TL_USD_BUYING || !TL_USD_SELLING) getExchangeRates();
  }, []);

  useEffect(() => {
    setProductTypes(productTypesMain);
  }, [productTypesMain]);

  useEffect(() => {
    setSeries(seriesMain);
  }, [seriesMain]);

  useEffect(() => {
    if (product) {
      if (product.series) {
        const product_series = typeof product.series === "object" ? product?.series?._id : product.series;
        const selectedSerie = series.find((serie) => serie?._id === product_series);
        setSeries(series => [
          selectedSerie,
          ...series.filter((serie) => serie?._id !== selectedSerie?._id)
        ]);
      }
      if (product.product_type) {
        const product_type = typeof product.product_type === "object" ? product?.product_type?._id : product.product_type;
        const selectedType = productTypes.find((type) => type?._id === product_type);
        setProductTypes(types => [
          selectedType,
          ...types.filter((type) => type?._id !== selectedType?._id)
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product?.series, product?.product_type]);

  useEffect(() => {
    setSkuCode(createSKU(handle));
  }, [handle, vendor]);

  useEffect(() => {
    setProduct(product => ({
      ...product,
      names : {...product?.names, tr : createSKU(handle) + " - " + createNameWithoutSku(product?.names?.tr)}
    }))
  }, [skuCode]);

  const getExchangeRates = () => {
    fetchExchangeRates({ abbreviation: "TRY" })
      .then(res => {
        setTL_USD_BUYING(res?.data?.rate_usd_buying)
        setTL_USD_SELLING(res?.data?.rate_usd_selling)
      })
  }

  const createSKU = (handle) => {
    if (handle === "new") {
      return `${vendor?.vendor_initial || ""}${(vendor?.vendor_product_count || 10000) + 1}`;
    } else {
      const isOld = !isNaN(handle?.split("-")[0][0]);
      const current_count = isOld ? handle?.split("-")[0] : handle?.split("-")[0]?.slice(3);
      return `${!isOld && vendor?.vendor_initial ? vendor?.vendor_initial : ""}${current_count}`;
    }
  }

  useEffect(() => {
    const priceForTag = discountCheckbox ? discountUSD : priceusd
    if(priceForTag){
      
      let tagName;
  
      if (priceForTag < 10) {
        tagName = 'Under $10';
      } else if (priceForTag < 20) {
        tagName = 'Between $10-$20';
      } else if (priceForTag < 30) {
        tagName = 'Between $20-$30';
      } else if (priceForTag < 40) {
        tagName = 'Between $30-$40';
      } else if (priceForTag < 50) {
        tagName = 'Between $40-$50';
      } else {
        tagName = 'Above $50';
      }
      
      const tagExists = product?.tags?.find((tag) => tag?.name == tagName);
  
      if(!tagExists){
        let newTags = product?.tags?.filter(tag => tag?.type != 'price')  
        
        const newPriceTags = tags?.find(tag => tag?.name == tagName)  
        newTags.push(newPriceTags)
        
        setProduct({...product, tags: newTags })
      }
  
    }
    
  },[priceusd, discountUSD, discountCheckbox])

  useEffect(() => {
    let newTags = product?.tags
    const isRestock = statusInitial === 'Passive' && product?.status === 'Active'
    const isAlreadyRestock  = newTags?.find(tag => tag?.name === 'RPT')
    if(isRestock){  
      if(!isAlreadyRestock){
        const restockTag = tags?.find(tag => tag?.name === 'RPT')
        newTags.push(restockTag)
        setProduct({...product, tags: newTags })
      }
    }else{
      newTags = newTags?.filter(tag => tag?.name !== 'RPT')
      setProduct({...product, tags: newTags })
    }
  },[statusInitial, product?.status])

  useEffect(() => {
    if (handle !== "new") {

      if (!TL_USD_SELLING || !TL_USD_BUYING) {
        getExchangeRates();
        return;
      }

      fetchProductByHandle({handle, mode : 'edit_product'})
        .then((res) => {
          form.setFieldsValue({
            status: res.data.status,
            supplierstatus: res.data.supplier_status,
            series: res.data.series?.name,
            productType: res.data.product_type?._id,
            ranking: res.data.ranking,
            stock: res.data.stock,
            supplier_stock: res.data.supplier_stock,
            supplier_stock_code: res.data?.supplier_stock_code,
            in_transit_stock: res.data?.in_transit_stock,
            reserved_stock: res.data?.reserved_stock,
            supplier_link: res.data?.supplier_link
          });
          setProduct(res.data);
          setPricetl(loncaRound(res.data?.price * TL_USD_SELLING));
          setPriceusd(loncaRound(res.data?.price));
          setCogstl(loncaRound(res.data?.cogs * TL_USD_SELLING));
          setCogsusd(loncaRound(res.data?.cogs));
          setMeasurement(res.data?.measurement);
          setDiscountCheckbox(!!res.data?.is_discounted);
          setDiscountTL(loncaRound((res.data?.discounted_price || 0) * TL_USD_SELLING) );
          setDiscountUSD(loncaRound((res.data?.discounted_price || 0) ));
          setDiscountCogsTL(loncaRound((res.data?.discounted_cogs || 0) * TL_USD_SELLING));
          setDiscountCogsUSD(loncaRound((res.data?.discounted_cogs || 0)));
          setFileList(res.data?.images.map((image, index) => {
            return {
              uid: index + 1,
              status: "done",
              url: image,
              name: `Image_${index + 1}`,
              existBeforeUpdate: true,
              is_main: res.data?.main_image === image
            }
          }))
          setVideoFileList(res.data?.videos?.map((video, index) => {
            return {
              uid: index + 1,
              status: "done",
              url: video,
              name: `Video_${index + 1}`,
              existBeforeUpdate: true,
            }
          }));
          setPriceInitial(res.data?.price);
          setStatusInitial(res.data?.status);
          setSeriesInitial(res.data?.series?._id);
          
          setProductTypeIdInitial(res.data?.product_type?._id);
          if (res.data?.size_chart) setChartFileList([{
            uid: 1,
            status: "done",
            url: res.data?.size_chart,
            name: `Size Chart Image`,
            existBeforeUpdate: true
          }]);

          if (res.data.product_group !== undefined) {
            fetchGroupById(res?.data?.product_group?._id)
              .then((res) => setProductGroup(res.data))
              .catch((err) => console.log(err));
          }
        })
        .catch((err) => console.log(err));
    }

    if (handle === "new") {
      setProduct({
        tags: [],
        color: [],
        gender: [],
      });
    }
  }, [handle, form, refresh, TL_USD_BUYING, TL_USD_SELLING]);

  useEffect(() => {
    if (isNewColorProductCreated) {
      fetchSubmitGroup().then(() => {
        notification['success']({
          message: t(`errors.New Color Product added to Color Group successfully`),
        });
        setIsNewColorProductCreated(false);
      }).catch((err) => {
        console.log(err);
        notification['error']({
          message: t(`errors.New Color Product couldn't added to Color Group successfully`)
        });
        setIsNewColorProductCreated(false);
      });
    }

  }, [isNewColorProductCreated]);

  const handleDesiChange = (change) => {

    let newMeasurement = {
      ...measurement,
      ...change,
    }

    newMeasurement.desi = Math.max(
      newMeasurement.weight,
      newMeasurement.height *
      newMeasurement.length *
      newMeasurement.width /
      DESI_CONSTANT
    )
    setMeasurement(newMeasurement);
    setProduct({
      ...product,
      measurement: {
        weight: parseFloat(newMeasurement.weight),
        height: parseFloat(newMeasurement.height),
        width: parseFloat(newMeasurement.width),
        length: parseFloat(newMeasurement.length),
        desi: parseFloat(newMeasurement.desi),
      }
    })
  }

  const handleSubmit = async () => {

    setLoading(true);

    // stock - status check
    if (!(product?.stock > 0) && !(product?.supplier_stock > 0) && product?.status === "Active") {
      notification['error']({
        message: t(`errors.Active products must have supplier stock.`),
      });

      setLoading(false);
      return;
    }

    if(
      ((parseFloat(discountTL) > parseFloat(pricetl)) || 
      (parseFloat(discountUSD) > parseFloat(priceusd)) || 
      (parseFloat(discountCogsTL) > parseFloat(cogstl)) || 
      (parseFloat(discountCogsUSD) > parseFloat(cogsusd))) && discountCheckbox) 
    {
      notification['error']({
        message: t(`errors.Discount price can't be bigger than product price`),
      });

      setLoading(false);
      return;
    }

    if (product?.price <= 0 || product?.cogs <= 0) {
      notification['error']({
        message: t(`errors.zero_price`),
      });

      setLoading(false);
      return;
    }

    if (discountCheckbox && (product?.discounted_price <= 0 || product?.discounted_cogs <= 0)) {
      notification['error']({
        message: t(`errors.zero__discounted_price`),
      });

      setLoading(false);
      return;
    }

    // new product season tag check
    const seasonTag = product?.tags?.find((tag) => tag?.type?.includes("season"));
    
    if ((product?.status === "Active") && (vendor?.status === "Passive")) {
      notification['error']({
        message: t(`errors.You can only enter Passive products when the vendor is not Active!`),
      });
      setLoading(false);
      return;
    }

    if ((handle === 'new') && (product?.status === "Active" || product?.status === "Passive") && !seasonTag) {
      notification['error']({
        message: t(`errors.Product needs a seasonal tag`),
      });
      setLoading(false);
      return;
    }

    if (fileList.length === 0) {
      notification['error']({
        message: t('errors.You need to upload at least one product image'),
      });
      setLoading(false)
      return
    }

    if (product?.color?.length === 0) {
      notification['error']({
        message: t(`errors.You should specify color`)
      });
      setLoading(false);
      return;
    }

    let changed = getChangedValues(product);

    if(handle === "new") {
      let productEnterLog = {
        role: user?.role,
        user: user?.full_name,
        type: "New Product"
      }
      changed = { ...changed, product_enter_log: productEnterLog };
    };

    let logs = [];

    if(product?.price !== priceInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Price",
        from: priceInitial,
        to: product?.price
      });
    }
  
    if(product?.status !== statusInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Status",
        from: statusInitial,
        to: product?.status
      });
    }

    if(!product?.series?._id && (product?.series !== seriesInitial)) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "Series",
        from: seriesInitial,
        to: product?.series
      });
    }

    if(product?.product_type?._id !== productTypeIdInitial) {
      logs.push({
        user: user["full_name"],
        created_at: (new Date(Date.now())).toISOString(),
        type: "ProductType",
        from: productTypeIdInitial,
        to: product?.product_type?._id
      });
    }

    if(logs.length !== 0) {
      const existingLogs = changed?.["change_log"] || [];
      changed = { ...changed, change_log: [ ...existingLogs, ...logs ] };
    }

    const skuForUplaod = handle !== "new" ? product?.product_code : skuCode;

    let imageLinks = [];
    let videoLinks = [];

    let selectedImage = fileList?.find(f => f?.is_main);
    let selectedImageLink = "";

    let existBeforeUpdateMainIndex = fileList?.findIndex(f => f?.is_main);
    let existBeforeUpdateCountTillIndex = fileList?.slice(0, existBeforeUpdateMainIndex + 1)?.filter(f => f?.existBeforeUpdate)?.length;

    if (fileList?.filter(i => !i?.existBeforeUpdate)?.length) imageLinks = await uploadMedias(
      vendor?.name,
      fileList?.filter(i => !i?.existBeforeUpdate),
      skuForUplaod,
      fileList?.filter(i => i?.existBeforeUpdate)?.length
    );
 
    if (videoFileList?.filter(i => !i?.existBeforeUpdate)?.length) {
      if(isMediaListExceedSizeThreshold(videoFileList?.filter(i => !i?.existBeforeUpdate))){
        notification['error']({
          message: "File is bigger than 100 MB. Can't upload",
        });
        setLoading(false)
        return
      }

      videoLinks = await uploadMedias(
      vendor?.name,
      videoFileList?.filter(i => !i?.existBeforeUpdate),
      skuForUplaod,
      videoFileList?.filter(i => i?.existBeforeUpdate)?.length,
      "product",
      'video'
    );

  }
    if (selectedImage?.existBeforeUpdate) {
      selectedImageLink = selectedImage?.url;
    } else {
      selectedImageLink = imageLinks[existBeforeUpdateMainIndex - existBeforeUpdateCountTillIndex];
    }

    imageLinks = [...(fileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(imageLinks || [])];

    if (imageLinks?.length === 0) {
      notification['error']({
        message: t(`errors.You should specify images`)
      });
      return;
    }

    let chartImage = "";

    if (chartFileList?.filter(i => !i?.existBeforeUpdate)?.length) chartImage = await uploadChartImage(
      vendor?.name,
      chartFileList,
      skuForUplaod
    );
    

    if (fileList.find(image => !image?.existBeforeUpdate)) {
      if (imageLinks.length === fileList?.filter(i => !i?.existBeforeUpdate).length) {
        notification['success']({
          message: 'All images loaded to AWS successfully',
        });
      } else {
        notification['error']({
          message: 'All images couldn\'t loaded to AWS successfully',
        });
      };
    }
    
    if (videoFileList.find(image => !image?.existBeforeUpdate)) {
      if (videoLinks.length === videoFileList?.filter(i => !i?.existBeforeUpdate).length) {
        notification['success']({
          message: 'All videos loaded to AWS successfully',
        });
      } else {
        notification['error']({
          message: 'All videos couldn\'t loaded to AWS successfully',
        });
      };
    }

    videoLinks = [...(videoFileList?.filter(i => i?.existBeforeUpdate)?.map(image => image?.url) || []), ...(videoLinks || [])];

    if (handle !== "new") {
      fetchUpdateProduct(product._id, changed, imageLinks, selectedImageLink, chartImage, videoLinks)
        .then((res) => {
          notification['success']({
            message: t(`errors.Product saved successfully`)
          });
          setProduct({ ...product, change_log: res?.data?.change_log });
          setPriceInitial(res?.data?.price);
          setStatusInitial(res?.data?.status);
          setSeriesInitial(res?.data?.series?._id);
          setProductTypeIdInitial(res.data?.product_type?._id);
          
          if (productGroupDirty) {
            fetchSubmitGroup().then(() => {
              notification['success']({
                message: t(`errors.Product group saved successfully`),
              });
            }).catch((err) => {
              console.log(err)
              notification['error']({
                message: t(`errors.Product group couldn't saved successfully`)
              });
            });
          }
        })
        .catch((err) => {
          console.log(err)
          notification['error']({
            message: t(`errors.Product couldn't saved successfully`),
          });
        });
    }
    if (handle === "new") {
      changed = {
        ...changed,
        vendor,
        series: seriesMain?.find(s => s?._id?.toString() === product?.series?.toString()),
      };

      fetchCreateProduct(changed, imageLinks, selectedImageLink, chartImage, vendor?.vendor_product_count)
        .then((res) => {
          notification['success']({
            message: t(`errors.Product created successfully`),
          });
          dispatch(fetchVendor({ _id: user.vendor }));
          navigate(-1);
        })
        .catch((err) => {
          console.log(err);
          if(err?.response?.status === 409) {
            notification['error']({
              message: 'You have possibly created a product for the same vendor in a separate tab! Refreshing the page...',
            });
            dispatch(fetchVendor({ _id: user.vendor }));
          } else {
            notification['error']({
              message: t(`errors.Product couldn't created successfully`),
            });
          }
        });
    }
    setLoading(false);
    setIsDirty(false);
  };

  const handlePick = (image) => {
    setSelectedImage(image?.value)
    setIsDirty(true);
  }

  const handleAddToGroup = (handle) => {

    fetchProductByHandle({handle, mode : 'edit_product'}).then((res) => {
      if (res?.data?.product_group?._id) {
        notification['error']({
          message: t(`errors.Product already in another group`),
        });
        return;
      }
      if (productGroup?.products?.find((item) => item.handle === res.data.handle)) {
        notification['error']({
          message: t(`errors.Product already in the group`),
        });
        return;
      }

      setProductGroup({ ...productGroup, products: [...(productGroup?.products), res.data] })
      setIsDirty(true);
      setProductGroupDirty(true);
    }
    ).catch((err) => {
      console.log(err);
      if (err?.response?.status === 404) {
        notification['error']({
          message: t(`errors.Product for product group not found`),
        });
      }
    })
  }

  const handleRemoveFromGroup = (productHere) => {

    setIsDirty(true);
    setProductGroupDirty(true);

    if (productHere.handle === product.handle) {
      setProductGroupRemoved([...productGroup.products.map((item) => item._id)]);
      setProductGroup({ ...productGroup, products: [] });
    } else {
      setProductGroupRemoved([...productGroupRemoved, productHere._id]);
      setProductGroup({ ...productGroup, products: productGroup.products.filter((item) => item.handle !== productHere.handle) });
    }
  }

  const fetchSubmitGroup = async () => {
    if (!productGroup._id) {
      await fetchInsertGroup({ ...productGroup, products: [...productGroup?.products, product._id] })
    } else {
      await fetchUpdateGroupById(productGroup._id, productGroup)
    }

    setRefresh(r => !r);
  }

  const deleteTag = (tag) => {
    setProduct({ ...product, tags: product.tags.filter((item) => item._id !== tag._id) });
    setIsDirty(true);
  };

  const deleteColor = (color) => {
    setProduct({ ...product, color: product.color.filter((item) => item._id !== color._id) });
    setIsDirty(true);
  };

  const deleteGender = (handle) => {
    setProduct({ ...product, gender: product.gender.filter((item) => item.handle !== handle) });
    setIsDirty(true);
  };

  const handlePreview = async (file, type) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewType(type);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };


  const handleCreateColor = (newProduct) => {
    setProductGroup({ ...productGroup, products: [...(productGroup?.products), newProduct] })
    setIsNewColorProductCreated(true)
  };

  const createNameWithoutSku = (name) => {
    const dashArr = name?.split("-");

    if (dashArr?.length) {
      return dashArr?.slice(1)?.join("-")?.trimStart();
    }

    return "";
  }
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const createNameWithSku = (handle, name) => {
    let sku = createSKU(handle);
    return sku + " - " + capitalize(name);
  }

  const capitalize = (text) => {
    let words = text.split(" ");
    words = words.map(word => (word[0]?.toUpperCase() || "") + (word?.slice(1)?.toLocaleLowerCase() || ""));
    return words.join(" ");
  }

  const handleCheckboxChange = () => {
    setDiscountCheckbox((prevChecked) => !prevChecked);

    setProduct((prevProduct) => ({
      ...prevProduct,
      is_discounted: !discountCheckbox, 
    }));
    
  };

  return (
    <div className="container" onClick={(e) => e.stopPropagation()}>
      <div className="product-details">
        <Form
          form={form}
          className="product-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Modal width={"1000px"} visible={isModalOpen} footer={null} onCancel={handleCancel}>
            <NewColorProduct
              isNew={true}
              product_type={product?.product_type}
              series={product?.series}
              gender={product?.gender}
              tags={product?.tags}
              size_chart={product?.size_chart}
              description_details={product?.description_details}
              cogs={product?.cogs}
              price={product?.price}
              measurement={product?.measurement}
              status={product?.status}
              vendor_product_group={product?.vendor_product_group}
              onCreateColor={handleCreateColor}
              onFinish={setIsModalOpen}
              visible={isModalOpen}
              sub_vendor_name={product?.sub_vendor_name}
            />
          </Modal>
          <div className="product-header-save">
            <div className="product-tabs">
              <div onClick={() => updateTabParam(`home`)} className={tab === "home" && "selected"}>{t(`home.Info`)}</div>
              <div onClick={() => updateTabParam(`tags`)} className={tab === "tags" && "selected"}>{t(`home.Tags`)}</div>
            </div>

            <h2 className="product-header">{getTranslatedProductName(product, selectedLanguage?.code)}</h2>
            <div>
              {!addNew && <Button type="ghost" onClick={showModal} style={{ marginRight: "10px", backgroundColor: 'orange' }}>
                {t(`buttons.Add New Color`)}
              </Button>}
              <Button type="primary" disabled={loading} loading={loading} className="button-margin" htmlType="submit" style={{ marginRight: "10px" }}>
                {t(`buttons.Save`)}
              </Button>
              <Button type="secondary" onClick={() => navigate(-1)}>
                {t(`buttons.Cancel`)}
              </Button>
            </div>
          </div>


          { tab === "home" ?
            <section>
              <div className="form-top">
                <div className="form-left">
                  <div className="inline-inputs">
                    <Form.Item label={t(`fields.product.Tr Name`)}>
                      <Input
                        value={product?.names?.tr}
                        disabled
                      />
                    </Form.Item>

                    <Form.Item label={t(`fields.new_products.Raw Tr Name`)}>
                      <Input
                        value={createNameWithoutSku(product?.names?.tr)}
                        required
                        onChange={(e) => {
                          setProduct({ ...product, names : {...product.names, tr : createNameWithSku(handle, e.target.value)}});                      
                          setIsDirty(true);
                        }}
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    <Form.Item label={t(`fields.product.Created At`)}>
                      <Input
                        value={loncaFormatDate(product?.createdAt)}
                        disabled
                      />
                    </Form.Item>
                  </div>

                  <div className="inline-inputs">
                    {addNew && <Form.Item label={t(`fields.new_products.SKU Code`)}>
                      <Input
                        value={skuCode}
                        disabled
                      />
                    </Form.Item>}
                  </div>
                  <Form.Item name="supplier_link" label={t(`fields.product.Supplier Link`)}>
                    <Input
                      value={product?.supplier_link}
                      min={0}
                      onChange={(e) => {
                        const supplier_link = e.target.value
                        setProduct({ ...product, supplier_link });
                        setIsDirty(true);
                      }}
                      className="input-width"
                    />
                  </Form.Item>
                  <Form.Item name="supplier_stock_code" label={t(`fields.product.Supplier Stock Code`)}>
                    <Input
                      value={product?.supplier_stock_code}
                      min={0}
                      onChange={(e) => {
                        const supplier_stock_code = e.target.value
                        setProduct({ ...product, supplier_stock_code });
                        setIsDirty(true);
                      }}
                      className="input-width"
                    />
                  </Form.Item>



                  {product?.sub_series?.length > 0  && product?.vendor?.is_misfire ?
                    <div className="stock-box">

                      {product?.sub_series?.map?.((subSeries, index) => {
                        return (
                          <div>
                            
                            <Form.Item label={subSeries?.series?.name + " - " + t(`fields.product.Supplier Stock`)}>
                              <InputNumber
                                value={subSeries?.supplier_stock}
                                min={0}
                                onChange={(e) => {
                                  const supplier_stock = parseInt(e)
                                  const updatedSubSeries = [...product?.sub_series];
                                  updatedSubSeries[index] = {
                                      ...updatedSubSeries[index],
                                      supplier_stock: supplier_stock
                                  };

                                  setProduct({ ...product, sub_series: updatedSubSeries }); 
                                  setIsDirty(true); 
                                }}
                                className="input-width"
                              />
                            </Form.Item>
                          </div>
                        )
                      })}

                    </div>
                    :
                    <div className="stock-box">
                      <Form.Item name="supplier_stock" label={t(`fields.product.Supplier Stock`)}>
                        <InputNumber
                          value={product?.supplier_stock}
                          min={0}
                          onChange={(e) => {
                            const supplier_stock = parseInt(e)
                            setProduct({ ...product, supplier_stock });
                            setIsDirty(true);
                          }}
                          className="input-width"
                        />
                      </Form.Item>
                    </div>        
                  }
                  

                  <div className="description_box">
                    <Form.Item label={t(`fields.product.Tr Description`)}>
                      <div className="inline-inputs">
                        <Form.Item label={t(`fields.product.Fabric`)}>
                          <TextArea
                              value={product?.description_details?.tr?.fabric}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  description_details: {
                                    ...product.description_details,
                                    tr : {
                                      ...product?.description_details?.tr,  fabric: capitalize(e.target.value)
                                    }
                                  }
                              });
                              setIsDirty(true);
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={t(`fields.product.Model Measurement`)}>
                          <TextArea
                            value={product?.description_details?.tr?.model_measurements}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                description_details: {
                                  ...product?.description_details,
                                  tr : {
                                  ...product?.description_details?.tr,  model_measurements: e.target.value
                                  }
                                }
                              });
                              setIsDirty(true);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div className="inline-inputs">
                        <Form.Item label={t(`fields.product.Sample Size`)}>
                          <TextArea
                            value={product?.description_details?.tr?.sample_size}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                description_details: {
                                  ...product?.description_details,
                                  tr : {
                                  ...product?.description_details?.tr,  sample_size: e.target.value
                                  }
                                }
                              });
                              setIsDirty(true);
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={t(`fields.product.Product Measurement`)}>
                          <TextArea
                            value={product?.description_details?.tr?.product_measurements}
                            onChange={(e) => {
                              setProduct({
                                ...product,
                                description_details: {
                                  ...product?.description_details,
                                  tr : {
                                  ...product?.description_details?.tr,  product_measurements: e.target.value
                                  }
                                }
                              });
                              setIsDirty(true);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>

                  </div>
                  <div className="description_box-cogs">
                    <div className="checkbox-container">
                      <label>
                        <input
                          type="checkbox"
                          checked={discountCheckbox}
                          onChange={handleCheckboxChange}
                        />
                        {t(`fields.product.Enable Discount`)}
                      </label>
                    </div>
                    <div className="inline-inputs vat-warning">
                      <p>
                        {t(`fields.product.vat_not_included`)}
                      </p>
                    </div>
                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Price(TL)`)}>
                        <Input
                          prefix="₺"
                          value={pricetl}
                          disabled={!vendor?.margin_included}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              price: loncaRound(e.target.value / TL_USD_BUYING),
                              cogs: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setPricetl(e.target.value);
                            setPriceusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                            setCogstl(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                            setCogsusd(loncaRound(parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin)
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Price(USD)`)}>
                        <Input
                          prefix="$"
                          value={priceusd}
                          disabled={!vendor?.margin_included}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              price: loncaRound(e.target.value),
                              cogs: loncaRound(parseFloat(e.target.value) / compoundMargin),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setPricetl(loncaRound(e.target.value * TL_USD_SELLING))
                            setPriceusd(e.target.value);
                            setCogstl(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING / compoundMargin));
                            setCogsusd(loncaRound(parseFloat(e.target.value || 0) / compoundMargin))
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.COGS(TL)`)}>
                        <Input
                          prefix="₺"
                          value={cogstl}
                          disabled={vendor?.margin_included}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              price: (loncaRound(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin),
                              cogs: loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING)),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setPricetl(loncaRound(e.target.value) * compoundMargin);
                            setPriceusd(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin);
                            setCogstl(e.target.value);
                            setCogsusd(loncaRound(e.target.value) / TL_USD_BUYING);
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.COGS(USD)`)}>
                        <Input
                          prefix="$"
                          value={cogsusd}
                          disabled={vendor?.margin_included}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              price: loncaRound(parseFloat(e.target.value) * compoundMargin),
                              cogs: loncaRound(e.target.value),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setPricetl(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                            setPriceusd(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                            setCogstl(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                            setCogsusd(e.target.value);
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Discounted Price(TL)`)}>
                        <Input
                          prefix="₺"
                          value={discountTL}
                          disabled={((discountCheckbox) ? (!vendor?.margin_included) : true )}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              discounted_price: loncaRound(e.target.value / TL_USD_BUYING),
                              discounted_cogs: loncaRound((parseFloat(e.target.value) / TL_USD_BUYING) / compoundMargin),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setDiscountTL(e.target.value);
                            setDiscountUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                            setDiscountCogsTL(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                            setDiscountCogsUSD(loncaRound((parseFloat(e.target.value || 0) / TL_USD_BUYING) / compoundMargin));
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Discounted Price(USD)`)}>
                        <Input
                          prefix="$"
                          value={discountUSD}
                          disabled={((discountCheckbox) ? (!vendor?.margin_included) : true )}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              discounted_price: loncaRound(e.target.value),
                              discounted_cogs: loncaRound((parseFloat(e.target.value) / compoundMargin)),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setDiscountUSD(e.target.value);
                            setDiscountTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                            setDiscountCogsTL(loncaRound((parseFloat(e.target.value || 0) * TL_USD_SELLING) / compoundMargin));
                            setDiscountCogsUSD(loncaRound(parseFloat(e.target.value || 0) / compoundMargin));
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>

                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Discounted COGS(TL)`)}>
                        <Input
                          prefix="₺"
                          value={discountCogsTL}
                          disabled={((discountCheckbox) ? (vendor?.margin_included) : true )}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              discounted_price: loncaRound(parseFloat(e.target.value) / TL_USD_BUYING * compoundMargin),
                              discounted_cogs: loncaRound((parseFloat(parseFloat(e.target.value) / TL_USD_BUYING))),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setDiscountTL(loncaRound(parseFloat(e.target.value) * compoundMargin));
                            setDiscountUSD(loncaRound(parseFloat(parseFloat(e.target.value) / TL_USD_BUYING) * compoundMargin));
                            setDiscountCogsTL(e.target.value);
                            setDiscountCogsUSD(loncaRound(parseFloat(e.target.value) / TL_USD_BUYING));
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Discounted COGS(USD)`)}>
                        <Input
                          prefix="$"
                          value={discountCogsUSD}
                          disabled={((discountCheckbox) ? (vendor?.margin_included) : true )}
                          onChange={(e) => {
                            setProduct({
                              ...product,
                              discounted_price: loncaRound(parseFloat(e.target.value) * compoundMargin),
                              discounted_cogs: loncaRound(e.target.value),
                              update_config: {...product?.update_config, price: 'fixed'}
                            });
                            setDiscountTL(loncaRound(parseFloat(e.target.value || 0) * TL_USD_SELLING * compoundMargin))
                            setDiscountUSD(loncaRound(parseFloat(e.target.value || 0) * compoundMargin));
                            setDiscountCogsTL(loncaRound(parseFloat(e.target.value) * TL_USD_SELLING));
                            setDiscountCogsUSD(e.target.value);
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>


                  </div>


                  {measurement && <div className="measurements-box">  <Form.Item label={t(`fields.product.Measurements`)} >

                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Length`)}>
                        <Input
                          prefix="cm"
                          value={measurement?.length}
                          onChange={(e) => {
                            handleDesiChange({ length: e.target.value });

                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Height`)}>
                        <Input
                          prefix="cm"
                          value={measurement?.height}
                          onChange={(e) => {
                            handleDesiChange({ height: e.target.value });

                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Width`)}>
                        <Input
                          prefix="cm"
                          value={measurement?.width}
                          onChange={(e) => {
                            handleDesiChange({ width: e.target.value });

                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Weight`)}>
                        <Input
                          prefix="kg"
                          value={measurement?.weight}
                          onChange={(e) => {
                            handleDesiChange({ weight: e.target.value });
                            setIsDirty(true);
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div className="inline-inputs">
                      <Form.Item label={t(`fields.product.Desi`)}>
                        <Input
                          prefix=""
                          value={measurement?.desi}
                          disabled
                        />
                      </Form.Item>

                    </div>
                  </Form.Item></div>}

                  <Form.Item label={t(`fields.product.Product Group`)} className="product-group-box">
                    <div className="product-group-container">
                      {productGroup?.products
                        ?.sort((item) => item.handle.localeCompare(product.handle))
                        ?.filter((item) => item?.handle !== product?.handle)
                        ?.map((item) => (
                          <div className="product-group-item" key={item.handle} onClick={(e) => {
                            e.preventDefault(); // prevent the default context menu from showing up
                            window.open(`/product/${item?.handle}`, "_blank")
                          }} >
                            <span className={`product-group-item-X ${item.handle === product.handle ? "important-x" : ""}`} onClick={(e) => {
                              e.stopPropagation()
                              if (item.handle === product.handle) {
                                if (window.confirm("This will delete the whole product group, do you want to continue?")) handleRemoveFromGroup(item);
                              } else {
                                handleRemoveFromGroup(item)
                              }
                            }}>X</span>
                            <span className="product-group-item-title">{item.names?.en}</span>
                            <span className="product-group-item-color">{item.color[0] ? item.color[0].color : 'NO COLOR MIGHT EXPLODE'}</span>
                          </div>
                        ))}
                    </div>
                    <div className="product-group-add">
                      { (!isFetchingAllProducts && allProductsHandles?.length === 0) && <Button
                        onClick={() => {
                          if (isFetchingAllProducts) return;

                          setIsFetchingAllProducts(true);

                          dispatch(fetchAllProductsHandle())
                            .then(() => {
                              setIsFetchingAllProducts(false);
                            })
                        }}
                      >
                        {t('fields.edit_order.Add new product')}
                      </Button>}

                      { isFetchingAllProducts && <Spin/> }

                      { allProductsHandles?.length > 0 &&
                        <div className="select-and-add">
                          <Select
                            onChange={(e) => { setProductGroupInput(e) }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                            }
                            placeholder={t(`placeholders.Add product by handle`)}
                          >
                            {allProductsHandles.map((handle) => (
                              <Option key={handle.handle} value={handle.handle}>
                                {handle.handle}
                              </Option>
                            ))}
                          </Select>
                          <Button type="primary" onClick={() => handleAddToGroup(productGroupInput)}>
                            {t(`buttons.Add`)}
                          </Button>
                        </div>
                      }
                    </div>
                  </Form.Item>
                </div>
                <div className="form-right">
                  {product?.stock > 0 && product?.supplier_status ? 
                    <Form.Item name="supplierstatus" label={t(`fields.product.Status`)}>
                      <Select
                        onChange={(e) => {
                          let updatedProduct = { ...product };
                          if (e === "Active") {
                            delete updatedProduct.supplier_status;
                            updatedProduct.status = e
                          } else {
                            updatedProduct.supplier_status = e;
                          }
                          setProduct({
                            ...updatedProduct,
                            update_config: { ...updatedProduct?.update_config, status: "fixed" },
                          });
                          setIsDirty(true);
                        }}
                      >
                        <Option value="Active">{t(`status.Active`)}</Option>
                        <Option value="Passive">{t(`status.Passive`)}</Option>
                        <Option value="Archived">{t(`status.Archived`)}</Option>
                      </Select>
                    </Form.Item>
                    :
                    <Form.Item name="status" label={t(`fields.product.Status`)}
                      rules={[{ required: true, message: t('errors.Please select a status') }]}>
                      <Select
                        onChange={(e) => {
                          if(product?.stock > 0) {
                            setProduct({
                              ...product,
                              supplier_status: e,
                              update_config: {...product?.update_config, status: 'fixed'}
                            });
                            form.setFieldsValue({
                              supplierstatus: e,
                            });
                          } else {
                            setProduct({
                              ...product,
                              status: e,
                              update_config: {...product?.update_config, status: 'fixed'}
                            });
                          }
                          
                          setIsDirty(true);
                        }}
                      >
                        <Option value="Active">{t(`status.Active`)}</Option>
                        <Option value="Passive">{t(`status.Passive`)}</Option>
                        <Option value="Archived">{t(`status.Archived`)}</Option>
                      </Select>
                    </Form.Item>
                  }                  
                  <Form.Item name="productType" label={t(`fields.product.Product Type`)}
                    rules={[{ required: true, message: t('errors.Please select a product type') }]}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          product_type: productTypes.find((type) => type?._id === e)
                        });
                        setIsDirty(true);
                      }}
                    >
                      {productTypes?.slice().sort((a, b) => t(`product_types.${a.name}`).localeCompare(t(`product_types.${b.name}`))).map((type, index) => (
                        <Option key={'type-select' + index} value={type?._id}>
                          {t(`product_types.${type?.name}`)}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="series" label={t(`fields.product.Series`)}
                    rules={[{ required: true, message: t('errors.Please select series') }]}>
                    <Select
                      onChange={(e) => {
                        setProduct({
                          ...product,
                          series: e,
                          update_config: {...product?.update_config, series: 'fixed'}
                        });
                        setIsDirty(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                      }
                    >
                      <Option value={null}>None</Option>
                      {series.map((serie, index) => (
                        <Option key={'series-select' + index} value={serie?._id}>
                          {serie?.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <UpdateConfigEditor product={product} setProduct={setProduct}/>
                  <Form.Item name="color" label={t(`fields.product.Colors`)}>
                    <Select
                      onChange={(e) => {
                        if (!product?.color?.find(color => color._id === e)) {
                          setProduct({ ...product, color: [...product.color,  colors.find((a) => a?._id === e)] });
                        };
                        setIsDirty(true);
                      }}
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                      }
                      placeholder={t(`placeholders.Add Color`)}
                    >

                      {colors?.slice().sort((a, b) => a.color.localeCompare(b.color)).filter(item1 => !product?.color?.find(item2 => item1?.color === item2?.color)).map((color, index) => (
                        <Option key={'colors-select' + index} value={color._id}>
                          {selectedLanguage?.code === "tr" ? color?.names.tr : (color?.names.en || color?.color)}
                        </Option>
                      ))}
                    </Select>
                    <div className="multi-container">
                      {product?.color?.length > 0 && product?.color?.map((color, index) => (
                        <div className="multi-item" key={index}>
                          <CloseOutlined onClick={() => deleteColor(color)} />
                          {selectedLanguage?.code === "tr" ? color?.names.tr : (color?.names.en || color?.color)}
                        </div>
                      ))}
                    </div>
                  </Form.Item>

                  {product?.gender && <Form.Item name="gender" label={t(`fields.product.Genders`)}>
                    <Select
                      onChange={(e) => {
                        const gender = genders.find((item) => item.handle === e);

                        setProduct({ ...product, gender: [...product.gender, gender] });

                        setIsDirty(true);
                      }}
                      showSearch
                      optionFilterProp="children"

                      placeholder={t(`placeholders.Add Gender`)}
                    >

                      {genders?.slice().filter(item1 => !product?.gender?.find(item2 => item1?.handle === item2?.handle)).map((gender, index) => (
                        <Option key={gender.handle} value={gender.handle}>
                          {t(`genders.${gender?.names?.en}`)}
                        </Option>
                      ))}
                    </Select>
                    <div className="multi-container">
                      {product?.gender?.length > 0 && product?.gender?.map((gender) => (
                        <div className="multi-item" key={gender.handle}>
                          <CloseOutlined onClick={() => deleteGender(gender.handle)} />
                          {t(`genders.${gender?.names?.en}`)}
                        </div>
                      ))}
                    </div>
                  </Form.Item>}

                  <Form.Item name="Size Chart" label={t(`fields.product.size_chart`)}>
                    <Upload
                      listType="picture-card"
                      multiple={true}
                      fileList={chartFileList}
                      onPreview={handlePreview}
                      onChange={({ file: updatedFile, fileList: newFileList }) => {
                        if (chartFileList.length < newFileList.length) {
                          setChartFileList(currentList => [...currentList, { ...updatedFile, status: "done" }]);
                        } else if (chartFileList.length > newFileList.length) {
                          setChartFileList(newFileList.filter(f => f.status !== "removed"));
                        }
                      }}
                    >
                      {chartFileList?.length < 1 ? <div>
                        <PlusOutlined />
                        <div
                          className="plus-outlined-margin"
                        >
                          Upload
                        </div></div> : null}
                    </Upload>

                  </Form.Item>

                </div>
              </div>
              <ImageBox
            fileList={fileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'image')}
            setFileList={setFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
          />

          <VideoBox
            fileList={videoFileList}
            product={product}
            handlePreview={(file) => handlePreview(file, 'video')}
            setFileList={setVideoFileList}
            getBase64={getBase64}
            previewVisible={previewVisible}
            previewTitle={previewTitle}
            previewType={previewType}
            previewImage={previewImage}
            setPreviewVisible={setPreviewVisible}
          />

            </section> 
            :
            <section>
              <TagSelector product={product} setProduct={setProduct} setIsDirty={setIsDirty}/>
            </section>
          }

        </Form>
      </div >
    </div >
  )
};

export default EditProduct;